import { Injectable } from '@angular/core';
import { mapFetchResultData } from '@x/common/graph';
import {
  AdjustOrderItemInput,
  AssignOrderAdjustmentInput,
  OrderFilterInput,
  OrderNoteInput,
  OrderTagInput,
  PrintOrderShipmentLabelsInput,
  RemoveOrderItemInput,
  RequestOrderShipmentSlotInput,
  RescheduleOrderShipmentInput,
  SetOrderShipmentFixedCostInput,
  StockItemFilterInput,
  UpdateCartCollectionPointInput,
  UpdateOrderAddressInput,
  UpdateOrderCustomerInput,
  UpdateOrderGiftOptionsInput,
  UpdateOrderInstructionsInput,
  UpdateOrderItemInput,
} from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import { ArchiveOrderNoteGQL, CreateOrderNoteGQL } from './gql/order-note.gql.generated';
import {
  AddOrderTagsGQL,
  AdjustOrderItemGQL,
  AllocateOrderFulfilmentGQL,
  ArchiveOrderGQL,
  AssignOrderAddressGQL,
  AssignOrderAdjustmentGQL,
  AssignOrderCustomerGQL,
  CancelOrderFulfilmentGQL,
  CancelOrderGQL,
  CancelOrderShipmentGQL,
  DeliverOrderShipmentGQL,
  ExpireOrderGQL,
  ExportStockItemQuantitiesGQL,
  OrderDetailGQL,
  OrderItemGQL,
  OrderItemsGQL,
  OrderItemsQueryVariables,
  OrderMapItemsGQL,
  OrderRowsGQL,
  OrderRowsQueryVariables,
  OrderSourceItemsGQL,
  OrderSourceItemsQueryVariables,
  PackOrderFulfilmentGQL,
  PrintOrderCourierSpreadsheetGQL,
  PrintOrderGiftMessageGQL,
  PrintOrderManifestGQL,
  PrintOrderManifestSpreadsheetGQL,
  PrintOrderReportGQL,
  PrintOrderShipmentLabelsGQL,
  PrintOrderTaxInvoiceGQL,
  PrintPackFleetReportGQL,
  PrintRouteManifestGQL,
  PrintRouteSummaryGQL,
  PrintRoyalMailReportGQL,
  ReconOrderShipmentGQL,
  RemoveOrderItemGQL,
  RemoveOrderTagsGQL,
  RequestOrderShipmentSlotGQL,
  RescheduleOrderShipmentGQL,
  ReserveOrderFulfilmentGQL,
  SendAbandonedCartEmailGQL,
  SendOrderEnquiryEmailGQL,
  SendOrderRecievedEmailGQL,
  SendOrdersShippedEmailGQL,
  SendRequestPaymentEmailGQL,
  SetOrderShipmentFixedCostGQL,
  ShipOrderShipmentGQL,
  SubmitOrderShipmentWaybillGQL,
  UnallocateOrderFulfilmentGQL,
  UnpackOrderFulfilmentGQL,
  UpdateCartCollectionPointGQL,
  UpdateOrderGiftOptionsGQL,
  UpdateOrderInstructionsGQL,
  UpdateOrderItemGQL,
} from './gql/order.gql.generated';
import {
  IOrderDetailObject,
  IOrderItemObject,
  IOrderRowObject,
  IOrderSourceItemObject,
  OrderMapItem,
} from './types/order';

@Injectable()
export class OrderService {
  constructor(
    private orderRowsGQL: OrderRowsGQL,
    private orderDetailGQL: OrderDetailGQL,
    private cancelGQL: CancelOrderGQL,
    private updateOrderItemGQL: UpdateOrderItemGQL,
    private removeOrderItemGQL: RemoveOrderItemGQL,
    private assignOrderAddressGQL: AssignOrderAddressGQL,
    private assignOrderCustomerGQL: AssignOrderCustomerGQL,
    private archiveOrderGQL: ArchiveOrderGQL,
    private updateOrderGiftGQL: UpdateOrderGiftOptionsGQL,
    private updateOrderInstructionsGQL: UpdateOrderInstructionsGQL,
    private assignOrderAdjustmentGQL: AssignOrderAdjustmentGQL,
    private orderItemsGQL: OrderItemsGQL,
    private orderItemGQL: OrderItemGQL,
    private requestOrderShipmentSlotGQL: RequestOrderShipmentSlotGQL,
    private printOrderManifestGQL: PrintOrderManifestGQL,
    private printOrderGiftMessageGQL: PrintOrderGiftMessageGQL,
    private printOrderTaxInvoiceGQL: PrintOrderTaxInvoiceGQL,
    private printOrderShipmentLabelsGQL: PrintOrderShipmentLabelsGQL,
    private adjustOrderItemGQL: AdjustOrderItemGQL,
    private reserveOrderFulfilmentGQL: ReserveOrderFulfilmentGQL,
    private allocateOrderFulfilmentGQL: AllocateOrderFulfilmentGQL,
    private unallocateOrderFulfilmentGQL: UnallocateOrderFulfilmentGQL,
    private packOrderFulfilmentGQL: PackOrderFulfilmentGQL,
    private unpackOrderFulfilmentGQL: UnpackOrderFulfilmentGQL,
    private submitOrderShipmentWaybillGQL: SubmitOrderShipmentWaybillGQL,
    private shipOrderShipmentGQL: ShipOrderShipmentGQL,
    private addOrderTagsGQL: AddOrderTagsGQL,
    private removeOrderTagsGQL: RemoveOrderTagsGQL,
    private cancelOrderFulfilmentGql: CancelOrderFulfilmentGQL,
    private reconOrderShipmentGQL: ReconOrderShipmentGQL,
    private createOrderNoteGQL: CreateOrderNoteGQL,
    private archiveOrderNoteGQL: ArchiveOrderNoteGQL,
    private cancelOrderShipmentGql: CancelOrderShipmentGQL,
    private updateCartCollectionPointGql: UpdateCartCollectionPointGQL,
    private sendAbandonedCartEmailGql: SendAbandonedCartEmailGQL,
    private sendOrderRecievedEmailGql: SendOrderRecievedEmailGQL,
    private sendOrderEnquiryEmailGql: SendOrderEnquiryEmailGQL,
    private sendOrdersShippedEmailGql: SendOrdersShippedEmailGQL,
    private sendRequestPaymentEmailGql: SendRequestPaymentEmailGQL,
    private rescheduleOrderShipmentGql: RescheduleOrderShipmentGQL,
    private printOrderRecordsGql: PrintOrderReportGQL,
    private printPackFleetReportGql: PrintPackFleetReportGQL,
    private expireGQL: ExpireOrderGQL,
    private deliverOrderShipmentGql: DeliverOrderShipmentGQL,
    private printOrderCourierSpreadsheetGQL: PrintOrderCourierSpreadsheetGQL,
    private printOrderManifestSpreadsheetGQL: PrintOrderManifestSpreadsheetGQL,
    private ExportStockItemQuantitiesGQL: ExportStockItemQuantitiesGQL,
    private printRouteManifestGQL: PrintRouteManifestGQL,
    private orderSourceItemsGQL: OrderSourceItemsGQL,
    private SetOrderShipmentFixedCostGQL: SetOrderShipmentFixedCostGQL,
    private printRoyalMailReportGQL: PrintRoyalMailReportGQL,
    private orderMapItemsGQL: OrderMapItemsGQL,
    private printRouteSummaryGQL: PrintRouteSummaryGQL,
  ) {}

  fetchItems(query: OrderItemsQueryVariables) {
    return this.orderItemsGQL.fetch(query).pipe(mapFetchResultData((r) => r));
  }

  fetchItem(id: number) {
    return this.orderItemGQL.fetch({ id }).pipe(mapFetchResultData((r) => r.order));
  }

  fetchRows(
    query: OrderRowsQueryVariables,
  ): Observable<{ items: IOrderRowObject[]; totalItemsCount: number }> {
    return this.orderRowsGQL.fetch(query).pipe(mapFetchResultData((r) => r));
  }

  fetchMapItems(
    query: OrderRowsQueryVariables,
  ): Observable<{ items: OrderMapItem[]; totalItemsCount: number }> {
    return this.orderMapItemsGQL.fetch(query).pipe(mapFetchResultData((r) => r));
  }

  fetchById(orderId: number): Observable<IOrderDetailObject> {
    return this.orderDetailGQL.fetch({ orderId }).pipe(mapFetchResultData((r) => r.order));
  }

  cancel(orderId: number): Observable<IOrderItemObject> {
    return this.cancelGQL.mutate({ orderId }).pipe(mapFetchResultData((r) => r.cancelOrder));
  }

  archive(orderId: number): Observable<IOrderItemObject> {
    return this.archiveOrderGQL.mutate({ orderId }).pipe(mapFetchResultData((r) => r.archiveOrder));
  }

  updateOrderItem(data: UpdateOrderItemInput): Observable<IOrderItemObject> {
    return this.updateOrderItemGQL
      .mutate({ data })
      .pipe(mapFetchResultData((r) => r.updateOrderItem));
  }

  updateCollectionPoint(input: UpdateCartCollectionPointInput) {
    return this.updateCartCollectionPointGql
      .mutate({ input })
      .pipe(mapFetchResultData((r) => r.updateCartCollectionPoint));
  }

  removeOrderItem(data: RemoveOrderItemInput): Observable<IOrderItemObject> {
    return this.removeOrderItemGQL
      .mutate({ data })
      .pipe(mapFetchResultData((r) => r.removeOrderItem));
  }

  assignOrderAddress(data: UpdateOrderAddressInput): Observable<IOrderItemObject> {
    return this.assignOrderAddressGQL
      .mutate({ data })
      .pipe(mapFetchResultData((r) => r.assignOrderAddress));
  }

  assignOrderCustomer(data: UpdateOrderCustomerInput): Observable<IOrderItemObject> {
    return this.assignOrderCustomerGQL
      .mutate({ data })
      .pipe(mapFetchResultData((r) => r.assignOrderCustomer));
  }

  updateOrderGiftOptions(data: UpdateOrderGiftOptionsInput): Observable<IOrderItemObject> {
    return this.updateOrderGiftGQL
      .mutate({ data })
      .pipe(mapFetchResultData((r) => r.updateOrderGiftOptions));
  }

  updateOrderInstructions(data: UpdateOrderInstructionsInput): Observable<IOrderItemObject> {
    return this.updateOrderInstructionsGQL
      .mutate({ data })
      .pipe(mapFetchResultData((r) => r.updateOrderInstructions));
  }

  assignOrderAdjustment(data: AssignOrderAdjustmentInput): Observable<IOrderItemObject> {
    return this.assignOrderAdjustmentGQL
      .mutate({ data })
      .pipe(mapFetchResultData((r) => r.assignOrderAdjustment));
  }

  requestOrderShipmentSlot(input: RequestOrderShipmentSlotInput) {
    return this.requestOrderShipmentSlotGQL
      .mutate({ input })
      .pipe(mapFetchResultData((d) => d.requestOrderShipmentSlot));
  }

  printOrderManifest(filter: OrderFilterInput) {
    return this.printOrderManifestGQL
      .mutate({ filter })
      .pipe(mapFetchResultData((d) => d.printOrderManifest));
  }

  printOrderGiftMessage(filter: OrderFilterInput) {
    return this.printOrderGiftMessageGQL
      .mutate({ filter })
      .pipe(mapFetchResultData((d) => d.printOrderGiftMessage));
  }

  printRouteManifest(orderFilter: OrderFilterInput, stockItemFilter: StockItemFilterInput) {
    return this.printRouteManifestGQL
      .mutate({ orderFilter, stockItemFilter })
      .pipe(mapFetchResultData((d) => d.printRouteManifest));
  }

  printRouteSummary(orderFilter: OrderFilterInput, stockItemFilter: StockItemFilterInput) {
    return this.printRouteSummaryGQL
      .mutate({ orderFilter, stockItemFilter })
      .pipe(mapFetchResultData((d) => d.printRouteSummary));
  }

  printOrderSpreadsheet(filter: OrderFilterInput, format?: string) {
    return this.printOrderRecordsGql
      .mutate({ filter, format })
      .pipe(mapFetchResultData((d) => d.printOrderReport));
  }

  printPackFleetSpreadsheet(filter: OrderFilterInput, format?: string) {
    return this.printPackFleetReportGql
      .mutate({ filter, format })
      .pipe(mapFetchResultData((d) => d.printPackFleetSpreadsheet));
  }

  printOrderCourierSpreadsheet(orderIds: number[], format?: string) {
    return this.printOrderCourierSpreadsheetGQL
      .mutate({ orderIds, format })
      .pipe(mapFetchResultData((d) => d.printOrderCourierSpreadsheet));
  }

  exportStockItemQuantities(args: {
    orderFilter: OrderFilterInput;
    stockItemFilter?: StockItemFilterInput;
    format?: string;
  }) {
    return this.ExportStockItemQuantitiesGQL.mutate(args).pipe(
      mapFetchResultData((d) => d.exportStockItemQuantities),
    );
  }

  printOrderManifestCsv(orderIds: number[]) {
    return this.printOrderManifestSpreadsheetGQL
      .mutate({ orderIds })
      .pipe(mapFetchResultData((d) => d.printOrderManifestSpreadsheet));
  }

  printOrderTaxInvoice(filter: OrderFilterInput) {
    return this.printOrderTaxInvoiceGQL
      .mutate({ filter })
      .pipe(mapFetchResultData((d) => d.printOrderTaxInvoice));
  }

  printOrderShipmentLabels(input: PrintOrderShipmentLabelsInput) {
    return this.printOrderShipmentLabelsGQL
      .mutate({ input })
      .pipe(mapFetchResultData((d) => d.printOrderShipmentLabels));
  }

  printRoyalMailSpreadsheet(filter: OrderFilterInput, format?: string) {
    return this.printRoyalMailReportGQL
      .mutate({ filter, format })
      .pipe(mapFetchResultData((d) => d.printRoyalMailReport));
  }

  adjustItem(data: AdjustOrderItemInput) {
    return this.adjustOrderItemGQL
      .mutate({ data })
      .pipe(mapFetchResultData((r) => r.adjustOrderItem));
  }

  reserveFulfilment(orderId: number) {
    return this.reserveOrderFulfilmentGQL
      .mutate({ orderId })
      .pipe(mapFetchResultData((r) => r.reserveOrderFulfilment));
  }

  allocateFulfilment(orderId: number) {
    return this.allocateOrderFulfilmentGQL
      .mutate({ orderId })
      .pipe(mapFetchResultData((r) => r.allocateOrderFulfilment));
  }

  unallocateFulfilment(orderId: number) {
    return this.unallocateOrderFulfilmentGQL
      .mutate({ orderId })
      .pipe(mapFetchResultData((r) => r.unallocateOrderFulfilment));
  }

  packFulfilment(orderId: number) {
    return this.packOrderFulfilmentGQL
      .mutate({ orderId })
      .pipe(mapFetchResultData((r) => r.packOrderFulfilment));
  }

  unpackFulfilment(orderId: number) {
    return this.unpackOrderFulfilmentGQL
      .mutate({ orderId })
      .pipe(mapFetchResultData((r) => r.unpackOrderFulfilment));
  }

  cancelFulfilment(orderId: number) {
    return this.cancelOrderFulfilmentGql
      .mutate({ orderId })
      .pipe(mapFetchResultData((r) => r.cancelOrderFulfilment));
  }

  reconShipment(orderId: number) {
    return this.reconOrderShipmentGQL
      .mutate({ orderId })
      .pipe(mapFetchResultData((r) => r.reconOrderShipment));
  }

  submitShipmentWaybill(orderId: number) {
    return this.submitOrderShipmentWaybillGQL
      .mutate({ orderId })
      .pipe(mapFetchResultData((r) => r.submitOrderShipmentWaybill));
  }

  shipShipment(orderId: number, sendNotification?: boolean) {
    return this.shipOrderShipmentGQL
      .mutate({ orderId, sendNotification })
      .pipe(mapFetchResultData((r) => r.shipOrderShipment));
  }

  deliverShipment(orderId: number, sendNotification?: boolean) {
    return this.deliverOrderShipmentGql
      .mutate({ orderId })
      .pipe(mapFetchResultData((r) => r.deliverOrderShipment));
  }

  cancelShipment(orderId: number) {
    return this.cancelOrderShipmentGql
      .mutate({ orderId })
      .pipe(mapFetchResultData((r) => r.cancelOrderShipment));
  }

  setShipmentFixedCost(input: SetOrderShipmentFixedCostInput) {
    return this.SetOrderShipmentFixedCostGQL.mutate({ input }).pipe(
      mapFetchResultData((r) => r.setOrderShipmentFixedCost),
    );
  }

  addTags(input: OrderTagInput) {
    return this.addOrderTagsGQL.mutate({ input }).pipe(mapFetchResultData((r) => r.addOrderTags));
  }

  removeTags(input: OrderTagInput) {
    return this.removeOrderTagsGQL
      .mutate({ input })
      .pipe(mapFetchResultData((r) => r.removeOrderTags));
  }

  createNote(input: OrderNoteInput) {
    return this.createOrderNoteGQL
      .mutate({ input })
      .pipe(mapFetchResultData((r) => r.createOrderNote));
  }

  archiveNote(noteId: number) {
    return this.archiveOrderNoteGQL
      .mutate({ id: noteId })
      .pipe(mapFetchResultData((r) => r.archiveOrderNote));
  }

  sendAbandonedCartEmail(orderId: number) {
    return this.sendAbandonedCartEmailGql
      .mutate({ orderId })
      .pipe(mapFetchResultData((r) => r.sendAbandonedCartEmail));
  }

  sendOrderRecievedEmail(orderId: number) {
    return this.sendOrderRecievedEmailGql
      .mutate({ orderId })
      .pipe(mapFetchResultData((r) => r.sendOrderRecievedEmail));
  }

  sendOrderEnquiryEmail(orderId: number) {
    return this.sendOrderEnquiryEmailGql
      .mutate({ orderId })
      .pipe(mapFetchResultData((r) => r.sendOrderEnquiryEmail));
  }

  sendOrderShippedEmail(orderId: number) {
    return this.sendOrdersShippedEmailGql
      .mutate({ orderId })
      .pipe(mapFetchResultData((r) => r.sendOrdersShippedEmail));
  }

  sendRequestPaymentEmail(orderId: number) {
    return this.sendRequestPaymentEmailGql
      .mutate({ orderId })
      .pipe(mapFetchResultData((r) => r.sendRequestPaymentEmail));
  }

  // sendFailedPaymentEmail(orderId: number) {
  //   return this.sendFailedPaymentEmailGql
  //     .mutate({ orderId })
  //     .pipe(mapFetchResultData((r) => r.sendFailedPaymentEmail));
  // }

  rescheduleOrderShipment(input: RescheduleOrderShipmentInput) {
    return this.rescheduleOrderShipmentGql
      .mutate({ input })
      .pipe(mapFetchResultData((d) => d.rescheduleOrderShipment));
  }

  expire(orderId: number, notifyUser?: boolean): Observable<IOrderItemObject> {
    return this.expireGQL
      .mutate({ orderId, notifyUser })
      .pipe(mapFetchResultData((r) => r.expireOrder));
  }

  fetchSourceItems(
    query: OrderSourceItemsQueryVariables,
  ): Observable<{ items: IOrderSourceItemObject[]; totalItemsCount: number }> {
    return this.orderSourceItemsGQL.fetch(query).pipe(mapFetchResultData((r) => r));
  }
}
